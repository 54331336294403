import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { user } = useAppContext();

  if (!user.isAuthenticated) {
    localStorage.setItem('lwd-userRedirect', `${pathname}${search}`);
  }

  return (
    <Route {...rest}>
      {user.isAuthenticated ? (
        children
      ) : (
        <Redirect to={
          `/login?redirect=${pathname}${search}`
        }
        />
      )}
    </Route>
  );
}
