/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import Amplify from 'aws-amplify';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import App from './App';
import config from './config';
import { auth } from './firebase';
import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.css';
import './assets/scss/argon-dashboard-react.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 5 * 1000 * 60,
    },
  },
});

Amplify.configure({
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolId: config.cognito.USER_POOL_ID,
    region: config.cognito.REGION,
    userPoolWebClientId: '43pmdduktmvmg35edhh4cdqnjq',
  },
  API: {
    endpoints: [
      {
        name: 'lw-dash',
        // endpoint: 'https://soix5kvv2h.execute-api.eu-west-2.amazonaws.com',
        endpoint: 'https://api.lightwave.productions',
        region: 'eu-west-2',
        custom_header: async () => ({ Authorization: `Bearer ${await auth.currentUser.getIdToken()}` }),
      },
      {
        name: 'lw-dash-http',
        endpoint: 'https://bjwrbgivgh.execute-api.eu-west-2.amazonaws.com',
        region: 'eu-west-2',
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: 'dashboard-csv-uploads',
      region: 'eu-west-2',
    },
  },
});

ReactDOM.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <NiceModal.Provider>
        <App />
      </NiceModal.Provider>
    </QueryClientProvider>
  </Router>,
  document.getElementById('root'),
);
