import React, { useEffect } from 'react';
import { Spinner } from 'reactstrap';

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export default function Loader() {
  useEffect(() => {
    document.body.classList.add('bg-dark');
    return () => document.body.classList.remove('bg-dark');
  }, []);
  return (

    <div style={containerStyle}>
      <div><Spinner color="primary" style={{ height: '5rem', width: '5rem' }} /></div>
    </div>
  );
}
