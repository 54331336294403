import React from 'react';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';
import { useSignOut } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { useAppContext } from '../libs/contextLib';
import { useLightwaveContext } from '../libs/lwContextLib';

export default function MainNavbar() {
  const { user, setUser } = useAppContext();
  const { dataContext } = useLightwaveContext();
  const [signOut] = useSignOut(auth);

  const onLogout = async () => {
    try {
      await signOut();
      setUser({ isAuthenticated: false });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark bg-dark" expand="md" id="navbar-main">
        <Container fluid>
          <span
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          >
            {dataContext.brandText}
          </span>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {user.initials}
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user.firstName}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">
                    Welcome
                    {' '}
                    {user.firstName}
                    !
                  </h6>
                </DropdownItem>
                <DropdownItem onClick={onLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
