import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
// core components
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { HotKeys } from 'react-hotkeys';
import MainNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import { routeList } from '../Routes';
import './Dash.css';
import { LightwaveContextProvider } from '../libs/lwContextLib';
// import PrinterStatus from '../components/PrinterStatus';
import 'react-toastify/dist/ReactToastify.css';

export default function Dash(props) {
  const { children } = props;
  const [dataContext, setDataContext] = useState({ labelLength: 0, brandText: 'Lightwave Productions' });
  const location = useLocation();
  const [showNav, setShowNav] = useState(true);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  // const [showPrinterStatus, setShowPrinterStatus] = useState(false);

  // useEffect(() => {
  //   if (location.pathname.startsWith('/barcodes')) {
  //     setShowPrinterStatus(true);
  //   } else {
  //     setShowPrinterStatus(false);
  //   }
  // }, [location]);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await API.get('lw-dash', '/settings/site');
        setDataContext((prev) => ({ ...prev, siteSettings: { ...settings } }));
        setSettingsLoaded(true);
      } catch (e) {
        console.log(e);
      }
    };
    loadSettings();
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/full-screen')) {
      setShowNav(false);
      setDataContext((prev) => ({ ...prev, fullScreen: false }));
    } else {
      setShowNav(true);
      setDataContext((prev) => ({ ...prev, fullScreen: true }));
    }
  }, [location]);

  return (
    <HotKeys>
      <ToastContainer />
      <LightwaveContextProvider value={{ dataContext, setDataContext }}>
        {showNav && (
          <>
            <Sidebar
              {...props}
              routes={routeList}
              logo={{
                innerLink: '/admin/index',
                imgSrc: '',
                imgAlt: '...',
              }}
            />
          </>
        )}
        <div className="main-content">
          <div className="stContainer">
            <div className="stContent">
              {dataContext.globalLoading && (
              <div className="lw-progress-bar">
                <div className="lw-progress-bar-value" />
              </div>
              )}
              {showNav && <MainNavbar {...props} />}
              {settingsLoaded && children}
            </div>
            {showNav && (
              <div className="stFooter">
                <Container fluid>
                  <Footer />
                </Container>
              </div>
            )}
          </div>
        </div>
      </LightwaveContextProvider>
      {/* {showPrinterStatus
    && (
    <PrinterStatus
      labelLength={dataContext.labelLength}
      printerState={dataContext.printerStatus}
      setDataContext={setDataContext}
    />
    )} */}
    </HotKeys>
  );
}

Dash.propTypes = {
  children: PropTypes.object.isRequired,
};
