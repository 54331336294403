/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { API } from 'aws-amplify';
import {
  NavLink as NavLinkRRD,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { useSignOut } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { routeList } from '../Routes';
import { useAppContext } from '../libs/contextLib';

export default function Sidebar() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const { user, setUser } = useAppContext();
  const routerLocation = useLocation();
  const [openSubNav, setOpenSubNav] = useState('');
  const [isAuth, setIsAuth] = useState(false);
  const [signOut] = useSignOut(auth);

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const processNavClick = (prop, e) => {
    if (!prop.subNav || prop.subNav.length === 0) {
      setCollapseOpen(false);
    } else {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (prop.path[0] === openSubNav) {
        setOpenSubNav('');
      } else {
        setOpenSubNav(prop.path[0]);
      }
    }
  };

  const onLogout = async () => {
    try {
      await signOut();
      setUser({ isAuthenticated: false });
    } catch (e) {
      console.log(e);
    }
  };
  const createLinks = (routes, level = 0) => routes.map((prop) => {
    if (!Array.isArray(prop?.scope) || !user.scope.some((r) => prop?.scope.includes(r))) return [];
    const checkNestedSubNav = (subNavProp) => subNavProp.some(
      (subNavItem) => subNavItem.path.includes(routerLocation.pathname)
            || subNavItem.path.some((subNavPath) => {
              const matchResult = matchPath(routerLocation.pathname, {
                path: subNavPath,
              });
              if (matchResult !== null && matchResult.isExact) {
                return true;
              }
              return false;
            }),
    )
        || subNavProp.some(
          (subNavItem) => subNavItem.subPages
            && subNavItem.subPages.length > 0
            && checkNestedSubNav(subNavItem.subPages),
        );

    const isActiveSubNav = prop.path.includes(openSubNav)
        || prop.path.includes(routerLocation.pathname)
        || (prop.subNav
          && prop.subNav.length > 0
          && checkNestedSubNav(prop.subNav));
    return (
      <NavItem key={prop.routeKey}>
        <NavLink
          to={prop.path[0]}
          tag={NavLinkRRD}
          onClick={(e) => processNavClick(prop, e)}
          isActive={(routeMatch, location) => {
            if (routeMatch && routeMatch.isExact) {
              return true;
            }

            for (let i = 0; i < prop.path.length; i += 1) {
              const matchResult = matchPath(location.pathname, {
                path: prop.path[i],
              });
              if (matchResult !== null && matchResult.isExact) {
                return true;
              }
            }

            if (prop.subPages && prop.subPages.length > 0) {
              return prop.subPages.some((subPage) => {
                for (let i = 0; i < subPage.path.length; i += 1) {
                  const subPageMatchResult = matchPath(location.pathname, {
                    path: subPage.path[i],
                  });
                  if (
                    subPageMatchResult !== null
                      && subPageMatchResult.isExact
                  ) {
                    return true;
                  }
                }

                return false;
              });
            }

            return false;
          }}
          activeClassName="active"
          style={
              level > 0
                ? { fontSize: '0.85rem', paddingLeft: `${3 * level}rem` }
                : {}
            }
        >
          {prop.icon && <i className={prop.icon} />}
          {prop.name}
          {level === 0
              && prop.subNav
              && prop.subNav.length > 0
              && (isActiveSubNav ? (
                <i className="fas fa-chevron-up ml-auto" />
              ) : (
                <i className="fas fa-chevron-down ml-auto" />
              ))}
        </NavLink>
        {level === 0
            && prop.subNav
            && prop.subNav.length > 0
            && isActiveSubNav && (
              <Nav
                navbar
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                {createLinks(prop.subNav, level + 1)}
              </Nav>
        )}
      </NavItem>
    );
  });

  // useEffect(() => {
  //   const checkAuth = async () => {
  //     try {
  //       await API.get('lw-dash', '/metrics/auth');
  //       setIsAuth(true);
  //     } catch (e) {
  //       setIsAuth(false);
  //     }
  //   };
  //   checkAuth();
  // }, []);

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        <NavbarBrand className="pt-0">
          <img
            alt="Lightwave Productions"
            className="navbar-brand-img"
            src="/images/logo_web.png"
          />
        </NavbarBrand>
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <span className="initials">{user.initials}</span>
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">
                  Welcome
                  {' '}
                  {user.firstName}
                </h6>
              </DropdownItem>
              <DropdownItem onClick={onLogout}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                {/* <Link to={logo.innerLink}> */}
                <img alt="Lightwave Productions" src="/images/logo_web.png" />
                {/* </Link> */}
              </Col>
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Navigation */}
          <Nav navbar>{createLinks(routeList)}</Nav>
          {/* Divider */}
          {/* <hr className="my-3" /> */}
          {/* Heading */}
          {/* <h6 className="navbar-heading text-muted">
            <i className="fas fa-link" />
            {' '}
            <span className="ml-3">Links</span>
          </h6> */}
          {/* Navigation */}
          {/* <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="https://lightwave.ui.com" target="_blank">
                <i className="fas fa-id-badge text-primary" />
                Hours & Approvals
              </NavLink>
            </NavItem>
          </Nav> */}
        </Collapse>
      </Container>
    </Navbar>
  );
}
