/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  FormGroup,
  UncontrolledAlert,
} from 'reactstrap';
import {
  useAuthState, useSignInWithMicrosoft, useSignInWithEmailAndPassword,
} from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import microsoftIcon from '../assets/img/icons/common/microsoft.svg';

function Copyright() {
  return (
    <small>
      {'Copyright © '}
      <a href="https://lightwave.productions">Lightwave Productions</a>
      {' '}
      {new Date().getFullYear()}
      .
    </small>
  );
}

export default function Login() {
  const [, loading] = useAuthState(auth);
  const [signInWithMicrosoft] = useSignInWithMicrosoft(auth);
  const [
    signInWithEmailAndPassword,
  ] = useSignInWithEmailAndPassword(auth);

  const loginEmailRef = useRef(null);
  const loginPasswordRef = useRef(null);

  const loginDirect = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(
        loginEmailRef.current.value, loginPasswordRef.current.value,
      );
    } catch (err) {
      console.log('error signing in', err);
    }
  };

  useEffect(() => {
    document.body.classList.add('bg-dark');
    return () => document.body.classList.remove('bg-dark');
  }, []);

  return (
    <>
      <div className="main-content">
        {/* <AuthNavbar /> */}
        <div className="header bg-gradient-warning py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-dark"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Button
                      color="default"
                      className="btn-neutral btn-icon"
                      onClick={() => signInWithMicrosoft([], { tenant: 'lightwaveproductions.co.uk' })}
                      disabled={loading}
                    >
                      <span className="btn-inner--icon">
                        <img
                          alt="..."
                          src={microsoftIcon}
                        />
                      </span>
                      <span className="btn-inner--text">Microsoft</span>
                    </Button>
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col lg="5" md="7">

              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="btn-wrapper text-center">
                    <form onSubmit={(e) => loginDirect(e)}>
                      <Col>
                        <FormGroup>
                          <input
                            className="form-control"
                            id="loginEmail"
                            placeholder="Email"
                            type="email"
                            ref={loginEmailRef}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <input className="form-control" ref={loginPasswordRef} placeholder="Password" id="loginPassword" type="password" />
                        </FormGroup>
                      </Col>
                      <Col>
                        <Button color="primary" outline type="submit">
                          Login
                        </Button>
                      </Col>

                    </form>
                  </div>
                </CardHeader>
              </Card>
              <Row className="mt-3">
                <Col xs="12">
                  <UncontrolledAlert color="default" fade hidden>
                    <span className="alert-inner--icon">
                      <i className="ni ni-lock-circle-open" />
                    </span>
                    {' '}
                    <span className="alert-inner--text">
                      <strong>Error</strong>
                      {' '}
                      Please enable third party cookies to login!
                    </span>
                  </UncontrolledAlert>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs="12 text-center">
                  <Copyright />
                </Col>
              </Row>
            </Col>

          </Row>
        </Container>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
}
