/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, Suspense } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Spinner } from 'reactstrap';
import { auth } from './firebase';
import Routes from './Routes';
import { AppContext } from './libs/contextLib';
import Loader from './components/Loader';
import PageContainer from './components/PageContainer';
import Dash from './layouts/Dash';

function App() {
  const [user, setUser] = useState(false);
  const [fbUser, loading] = useAuthState(auth);

  useEffect(async () => {
    if (fbUser) {
      const { claims } = await fbUser.getIdTokenResult();
      const scope = (claims?.scp && claims.scp.split(' ')) || ['lightwave/generic'];
      const { group } = claims;

      const initials = `${fbUser.displayName.substr(0, 1)}${fbUser.displayName.split(' ')[1].substr(0, 1)}`;
      const firstName = fbUser.displayName.indexOf(' ') === -1
        ? fbUser.displayName : fbUser.displayName.substr(0, fbUser.displayName.indexOf(' '));
      localStorage.setItem('lwd-email', fbUser.email);
      localStorage.setItem('lwd-userName', fbUser.displayName);
      localStorage.setItem('lwd-initials', initials);
      localStorage.setItem('lwd-firstName', firstName);
      setUser((prevUser) => ({
        ...prevUser,
        email: fbUser.email,
        name: fbUser.name,
        scope,
        group,
        initials,
        firstName,
        isAuthenticated: true,
        uid: fbUser.uid,
      }));
      // try {
      //   const options = {
      //     headers: {
      //       Authorization: `Bearer ${await fbUser.getIdToken()}`,
      //     },
      //   };
      //   const response = await API.get('lw-dash-http', '/temp/test', options);
      //   console.log(response);
      // } catch (e) {
      //   console.log(e);
      // }
    }
  }, [fbUser, loading]);

  const RenderAuthOrAdmin = () => {
    if (user.isAuthenticated) {
      return (
        <Dash>
          <Suspense fallback={<PageContainer><Spinner /></PageContainer>}>
            <Routes />
          </Suspense>
        </Dash>
      );
    }
    return <Routes />;
  };

  return (
    <>
      {loading
        ? <Loader />
        : (
          <AppContext.Provider value={{
            user, setUser,
          }}
          >
            <RenderAuthOrAdmin />
          </AppContext.Provider>
        )}
    </>
  );
}

export default App;
