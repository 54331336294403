/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import { initializeApp } from 'firebase/app';
import {
  getAuth,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDQD_2UQX_InmEBxZww_cwi9IY39siQt6U',
  authDomain: 'lightwave-warehouse-dashboard.firebaseapp.com',
  projectId: 'lightwave-warehouse-dashboard',
  storageBucket: 'lightwave-warehouse-dashboard.appspot.com',
  messagingSenderId: '35491206919',
  appId: '1:35491206919:web:fb735130990353ce5397a2',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

export {
  auth, storage,
};
