import React from 'react';
import {
  Button, Card, CardHeader, CardBody, Container, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';

export default function PageContainer({
  showHeader = true, title, children, returnLink, rhs,
}) {
  return (
    <>
      {showHeader
      && (
      <>
        <div className="header bg-gradient-dark pb-6 pt-6 pt-md-8" />
        <Container className="mt--7" fluid>
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  {returnLink && (
                  <Button size="sm" className="float-left btn-3 btn-icon" color="default" tyoe="button" tag={Link} to={returnLink}>
                    <span className="btn-inner--icon">
                      <i className="ni ni-bold-left" />
                    </span>
                    Back
                  </Button>
                  )}
                  <h3 className="float-left mb-0">
                    {title}
                  </h3>
                  {rhs}
                </CardHeader>
                <CardBody>
                  {children}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

      </>
      )}
      {!showHeader && (
      <Container className="mt-3" fluid>
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                {returnLink && (
                <Button size="sm" className="float-left btn-3 btn-icon" color="default" tyoe="button" tag={Link} to={returnLink}>
                  <span className="btn-inner--icon">
                    <i className="ni ni-bold-left" />
                  </span>
                  Back
                </Button>
                )}
                <h3 className="float-left mb-0">
                  {title}
                </h3>
                {rhs}
              </CardHeader>
              <CardBody>
                {children}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      )}
    </>
  );
}
