const config = {
  apiGateway: {
    REGION: 'eu-west-2',
    URL: 'https://dash-api.lightwave.productions',
  },
  cognito: {
    REGION: 'eu-west-2',
    IDENTITY_POOL_ID: 'eu-west-2:7eb68ad4-a604-443e-b7d2-8c79fc54c5a8',
    USER_POOL_ID: 'eu-west-2_YKpT841ev',
  },
};

export default config;
